<template lang="pug">
  .container.c-mobile__center
    section.navbar
      .navbar__item.navbar__item-left
        <router-link class="navbar__item-link" to="/constructor/">
          <svg-icon name="guide"/>
          span Конструктор
        </router-link>
        button.navbar__item-btn(v-if="$root.choseTemplateView && $root.choseTemplateView != 'view'", @click="$root.hideTemplate()")
          <svg-icon name="arrow"/>
      h1.c-title {{$root.choseTemplateView ? title : "ГИД ПО ВЫБОРУ БРИЛЛИАНТОВ"}}
      .navbar__item.navbar__item-right
        button.navbar__item-btn(v-if="$root.choseTemplateView === 'view'", @click="$root.choseTemplateView = 'form'")
          <svg-icon name="close"/>
        span.navbar__item-link(@click="show()", v-if="$root.window.width > 1199")
          <svg-icon name="consult"/>
          span Консультация
    section.c-guide
        template(v-if="$root.choseTemplateView === 'view'")
          .c-guide__detail.c-guide__detail-view
            .c-guide__detail-pic
                <img :src="require(`@/assets/img/content/forms/${val_pic}`)" alt="">
            .c-guide__detail-body
                .c-guide__title {{val_form}}
                .c-guide__text {{val_text}}
        template(v-else-if="$root.choseTemplateView === 'form'")
            .c-guide__text Огранка камней представляет собой сложный процесс, в ходе которого алмазам придается необходимая форма. Задача мастера состоит в том, чтобы поверхность невзрачного камня стала гладкой и на ней проявились четкие грани.
                .c-guide__list
                    label.c-guide__list-item(v-for="(item, i) in $root.forms")
                        input(:id="'form'+i", type="radio", name="form", v-model="val_form", :value="item.name", @change="$root.choseTemplateView = 'view'")
                        .c-guide__list-item__body
                            .c-guide__list-item__pic
                                <img :src="require(`@/assets/img/content/forms/${item.img}`)" alt="">
                            .c-guide__list-item__name {{item.name}}
            .c-guide__detail
                .c-guide__detail-pic
                    <img :src="require(`@/assets/img/content/forms/${val_pic}`)" alt="">
                .c-guide__detail-body
                    .c-guide__title {{val_form}}
                    .c-guide__text {{val_text}}
        template(v-else-if="$root.choseTemplateView === 'weight'")
            .c-guide__text Вес камня исчисляется в каратах. Каратность важна для определения стоимости. Меньше всего ценится крошка (пыль) и мелкие ограненные алмазы до 0.3 мм. Самородки крупные (от 1 ct) или средние (0.3–0.999 ct) стоят в 15 раз больше такого же веса минералов маленького калибра.
            .c-guide__weight
                .c-constructor__weight-input.c-constructor__weight-input--left
                    input(v-model="weight", type="number", :min="min_weight" :max="max_weight")
                    .c-guide__weight-legend грамм
                <vue-slider v-model="weight" :dotSize="29" :tooltipPlacement="'bottom'" :marks="[min_weight, max_weight]" :min="min_weight" :max="max_weight" :interval="0.01" :height="3" :process-style="{ backgroundColor: 'transparent' }" :rail-style="{ background: 'linear-gradient(77.93deg, rgba(2,2,32,0.3) 0%, #020220 100%)' }">
                  <template v-slot:step="{ label, active }" v-slot:dot>
                    <div :class="['custom-dot']"></div>
                  </template>
                </vue-slider>
                .c-constructor__weight-input
                    input(v-model="weight", type="number", :min="min_weight" :max="max_weight")
                    .c-guide__weight-legend карат
        template(v-else-if="$root.choseTemplateView === 'color'")
            .c-guide__text Характеризует степень поглощения белого света внутри бриллианта.
            ul.c-guide__info
                li.c-guide__info-item
                    .c-guide__info-pic
                        <img src="@/assets/img/content/forms/color1.png" alt="">
                    .c-guide__info-body
                        .c-guide__info-title
                            span 1(D)
                        .c-guide__info-text Идеально прозрачный
                li.c-guide__info-item
                    .c-guide__info-pic
                        <img src="@/assets/img/content/forms/color2.png" alt="">
                    .c-guide__info-body
                        .c-guide__info-title
                            span 2(E)
                            span 3(F)
                        .c-guide__info-text С незаметным оттенком
                li.c-guide__info-item
                    .c-guide__info-pic
                        <img src="@/assets/img/content/forms/color3.png" alt="">
                    .c-guide__info-body
                        .c-guide__info-title
                            span 4(G)
                            span 5(H)
                        .c-guide__info-text С незначительным оттенком
                li.c-guide__info-item
                    .c-guide__info-pic
                        <img src="@/assets/img/content/forms/color4.png" alt="">
                    .c-guide__info-body
                        .c-guide__info-title
                            span 6(I)
                            span 7(J)
                        .c-guide__info-text С заметным оттенком
        template(v-else-if="$root.choseTemplateView === 'clear'")
            .c-guide__text Обозначает наличие и количество вкраплений в бриллианте.
            ul.c-guide__info
              li.c-guide__info-item
                  .c-guide__info-pic
                      <img src="@/assets/img/content/forms/clear1.png" alt="">
                  .c-guide__info-body
                      .c-guide__info-title
                          span 1(IF)
                      .c-guide__info-text Без включений
              li.c-guide__info-item
                  .c-guide__info-pic
                      <img src="@/assets/img/content/forms/clear2.png" alt="">
                  .c-guide__info-body
                      .c-guide__info-title
                          span 2(VVS1)
                          span 3(VVS2)
                      .c-guide__info-text Мелкие незаметные включения
              li.c-guide__info-item
                  .c-guide__info-pic
                      <img src="@/assets/img/content/forms/clear3.png" alt="">
                  .c-guide__info-body
                      .c-guide__info-title
                          span 4(VS1)
                          span 5(VS2)
                      .c-guide__info-text Мелкие включения
              li.c-guide__info-item
                  .c-guide__info-pic
                      <img src="@/assets/img/content/forms/clear4.png" alt="">
                  .c-guide__info-body
                      .c-guide__info-title
                          span 6(SI1)
                          span 7(SI2)
                          span 7a(SI3)
                      .c-guide__info-text Мелкие заметные включения
        template(v-else-if="$root.window.width < 1199")
            .c-mobile__center
                .c-mobile__block
                    .c-constructor__chose
                        .c-constructor__chose-item(v-for="item in $root.choses", @click="$root.choseTemplate(item.id), title=item.name")
                            .c-constructor__chose-icon
                                <svg-icon :name="item.img" class="icon__color-accent"/>
                            .c-constructor__chose-name {{item.name}}
        template(v-else)
            .c-guide__inner
                .c-guide__left
                    .c-guide__title ФОРМА БРИЛЛИАНТА
                    .c-guide__text Огранка камней представляет собой сложный процесс, в ходе которого алмазам придается необходимая форма. Задача мастера состоит в том, чтобы поверхность невзрачного камня стала гладкой и на ней проявились четкие грани.
                    .c-guide__list
                        label.c-guide__list-item(v-for="(item, i) in $root.forms")
                            input(:id="'form'+i", type="radio", name="form", v-model="val_form", :value="item.name")
                            .c-guide__list-item__body
                                .c-guide__list-item__pic
                                    <img :src="require(`@/assets/img/content/forms/${item.img}`)" alt="">
                                .c-guide__list-item__name {{item.name}}
                    .c-guide__detail
                        .c-guide__detail-pic
                            <img :src="require(`@/assets/img/content/forms/${val_pic}`)" alt="">
                        .c-guide__detail-body
                            .c-guide__title {{val_form}}
                            .c-guide__text {{val_text}}
                .c-guide__right
                    .c-guide__block
                        .c-guide__title ВЕС
                        .c-guide__text Вес камня исчисляется в каратах. Каратность важна для определения стоимости. Меньше всего ценится крошка (пыль) и мелкие ограненные алмазы до 0.3 мм. Самородки крупные (от 1 ct) или средние (0.3–0.999 ct) стоят в 15 раз больше такого же веса минералов маленького калибра.
                        .c-guide__weight
                            .c-constructor__weight-input.c-constructor__weight-input--left
                                input(v-model="gram", type="number", :min="min_weight/5" :max="max_weight/5" @change="changeGram")
                                .c-guide__weight-legend грамм
                            <vue-slider v-model="weight" :dotSize="29" :tooltipPlacement="'bottom'" :marks="[min_weight, max_weight]" :min="min_weight" :max="max_weight" :interval="0.01" :height="3" :process-style="{ backgroundColor: 'transparent' }" :rail-style="{ background: 'linear-gradient(77.93deg, rgba(2,2,32,0.3) 0%, #020220 100%)' }">
                                <template v-slot:step="{ label, active }" v-slot:dot>
                                    <div :class="['custom-dot']"></div>
                                </template>
                            </vue-slider>
                            .c-constructor__weight-input
                                input(v-model="weight", type="number", :min="min_weight" :max="max_weight")
                                .c-guide__weight-legend карат
                    .c-guide__right-inner
                        .c-guide__block
                            .c-guide__title ЦВЕТ
                            .c-guide__text Характеризует степень поглощения белого света внутри бриллианта.
                            ul.c-guide__info
                                li.c-guide__info-item
                                    .c-guide__info-pic
                                        <img src="@/assets/img/content/forms/color1.png" alt="">
                                    .c-guide__info-body
                                        .c-guide__info-title
                                            span 1(D)
                                        .c-guide__info-text Идеально прозрачный
                                li.c-guide__info-item
                                    .c-guide__info-pic
                                        <img src="@/assets/img/content/forms/color2.png" alt="">
                                    .c-guide__info-body
                                        .c-guide__info-title
                                            span 2(E)
                                            span 3(F)
                                        .c-guide__info-text С незаметным оттенком
                                li.c-guide__info-item
                                    .c-guide__info-pic
                                        <img src="@/assets/img/content/forms/color3.png" alt="">
                                    .c-guide__info-body
                                        .c-guide__info-title
                                            span 4(G)
                                            span 5(H)
                                        .c-guide__info-text С незначительным оттенком
                                li.c-guide__info-item
                                    .c-guide__info-pic
                                        <img src="@/assets/img/content/forms/color4.png" alt="">
                                    .c-guide__info-body
                                        .c-guide__info-title
                                            span 6(I)
                                            span 7(J)
                                        .c-guide__info-text С заметным оттенком
                        .c-guide__block
                            .c-guide__title ЧИСТОТА
                            .c-guide__text Обозначает наличие и количество вкраплений в бриллианте.
                            ul.c-guide__info
                                li.c-guide__info-item
                                    .c-guide__info-pic
                                        <img src="@/assets/img/content/forms/clear1.png" alt="">
                                    .c-guide__info-body
                                        .c-guide__info-title
                                            span 1(IF)
                                        .c-guide__info-text Без включений
                                li.c-guide__info-item
                                    .c-guide__info-pic
                                        <img src="@/assets/img/content/forms/clear2.png" alt="">
                                    .c-guide__info-body
                                        .c-guide__info-title
                                            span 2(VVS1)
                                            span 3(VVS2)
                                        .c-guide__info-text Мелкие незаметные включения
                                li.c-guide__info-item
                                    .c-guide__info-pic
                                        <img src="@/assets/img/content/forms/clear3.png" alt="">
                                    .c-guide__info-body
                                        .c-guide__info-title
                                            span 4(VS1)
                                            span 5(VS2)
                                        .c-guide__info-text Мелкие включения
                                li.c-guide__info-item
                                    .c-guide__info-pic
                                        <img src="@/assets/img/content/forms/clear4.png" alt="">
                                    .c-guide__info-body
                                        .c-guide__info-title
                                            span 6(SI1)
                                            span 7(SI2)
                                            span 7a(SI3)
                                        .c-guide__info-text Мелкие заметные включения
</template>

<script>
export default {
  name: 'Guide',
  metaInfo: {
    title: 'Гид по выбору бриллиантов | OnlyDiamond',
    meta: [
      {
        name: 'description',
        content:
          'Выбор нужного бриллианта всегда кажется таким сложным. Прежде чем приобретать камень, вы  должны знать об особенностях огранки бриллиантов, оценке чистоты, оценке цвета, пропорциях алмазов.',
      },
    ],
  },
  data() {
    return {
      title: 'ГИД ПО ВЫБОРУ БРИЛЛИАНТОВ',
      weight: this.$root.min_weight,
      min_weight: this.$root.min_weight,
      max_weight: this.$root.max_weight,
      val_form: this.$root.forms[0].name,
      val_color: this.$root.colors[0].name,
      val_clear: this.$root.clears[0].name,
    }
  },
  methods: {
    show() {
      this.$modal.show('order')
    },
    changeGram() {
      this.weight = (this.gram * 5).toFixed(2)
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    val_pic: function() {
      var result = this.$root.forms.filter(x => {
        return x.name == this.val_form
      })
      if (result.length) {
        return result[0].img
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    val_text: function() {
      var result = this.$root.forms.filter(x => {
        return x.name == this.val_form
      })
      if (result.length) {
        return result[0].text
      }
    },
    gram: {
      get() {
        return (this.weight / 5).toFixed(3)
      },
      set(value) {
        this.weight = (value * 5).toFixed(2)
        return value
      },
    },
  },
}
</script>
